import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import FontStyles from './fontStyles';

import {store} from './store';

let initConfig = null;

export default {
  config: (config) =>{
    initConfig = config
  },
  widgets: {
    init: () => {
      return {
        render: () => {
          ReactDOM.render(
            <Provider store={store}>
              <FontStyles />
              <App 
                appID={initConfig.appID}
                order={initConfig.order}
                headerTitle={initConfig.headerTitle}
                selector={initConfig.selector}  
              />
            </Provider>
          , document.querySelector('#widget-app'))
        },
        unmount(){
          ReactDOM.unmountComponentAtNode(document.querySelector(initConfig.selector)); 
        },
      }
    }
  }
}
