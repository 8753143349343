/**
 * * Common
 */
export const ROUTE = '/v1'

export const MODEL_CHECKOUT = process.env.REACT_APP_ROUTE_CHECKOUT
export const MODEL = process.env.REACT_APP_ROUTE_SIGNATURE

/**
 * * User
 * GET: https://api-stag-ktn.esollabs.com/v1/dapp/user?address={_address}
 * POST: https://api-stag-ktn.esollabs.com/v1/dapp/user
 */

export const GET_APP_ID_MERCHANT = `${ROUTE}/${MODEL_CHECKOUT}/merchant_payment_methods`
export const GET_PAYMENT_METHOD = `${ROUTE}/${MODEL_CHECKOUT}/payment_methods`

export const CREATE_SIGNATURE = `${ROUTE}/${MODEL}/sacombank`
export const CREATE_URL_PAYMENT = `${ROUTE}/${MODEL}/sacombank/order`

export const GET_ORDER_TRANSACTION = `${ROUTE}/${MODEL_CHECKOUT}/order`



