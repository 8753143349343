
import axiosClient from "./axiosClient"

import { CREATE_SIGNATURE, CREATE_URL_PAYMENT, GET_APP_ID_MERCHANT ,GET_ORDER_TRANSACTION,GET_PAYMENT_METHOD } from "./endpoint"

export const merchantService = {

  getAppIDSignature: (queryParams) => {
    return axiosClient.get(GET_APP_ID_MERCHANT, { 
        params: queryParams 
    })
  },

  getPaymentMethod: (queryParams) => {
    return axiosClient.get(GET_PAYMENT_METHOD, { 
        params: queryParams 
    })
  },
  
  createSignature: (params) => {
    return axiosClient.post(CREATE_SIGNATURE, params)
  },

  getOrderIDTransaction: ({_id}) => {
    return axiosClient.get(`${GET_ORDER_TRANSACTION}/${_id}`);
  },

  createURLPayment: (params) => {
    return axiosClient.post(CREATE_URL_PAYMENT, params)
  },

}





