
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMethodItems } from '../../reducers/methodsSlice';

import { selectMerchantInfo } from '../../reducers/merchantSlice';
import { createSignature, fetchAppID, fetchPaymentMethod } from '../../actions/paymentActions';

import { BoxMethod, Container, Title } from "./styled";
import { BoxPayment } from '../checkout/styled';

import OrderInfo from '../../components/checkout/OrderInfo';

import AlertMessage from '../../components/common/AlertMessage';
import PaymentMethod from '../../components/checkout/PaymentMethod';
import BtnCheckout from '../../components/checkout/BtnCheckout';
import LoadingPage from '../../components/loading';

const KEY_ID_CHECKOUT_SACOMBANK = "63896905fdc876c9c9dcfe41"
const KEY_ID_CHECKOUT_QR = "63896944fdc876c9c9dcfe42"

const PaymentWidget = ({props}) => {

    const dispatch = useDispatch()

    const methods = useSelector(selectMethodItems)
    const merchant = useSelector(selectMerchantInfo)

    const [activeMethod, setActiveMethod] = useState("")
    const [msg, setMsg] = useState("")

    useEffect(() => {
        if (props && props.appID) {
          fetchMerchant(props.appID)
        }
    }, [props])

    const fetchMerchant = async (app_id) => {
      const data = await dispatch(fetchAppID({ app_id }))
      if(data.payload.msg){
        setMsg(data.payload.msg)
      }
      if (data) {
        await dispatch(fetchPaymentMethod({}))
      }
    }
  
    const checkoutOrder = async () => {
      switch (activeMethod) {

        case KEY_ID_CHECKOUT_SACOMBANK:
          createSignPayment()
          break;

        case KEY_ID_CHECKOUT_QR:
          alert("coming soon!!")
          break;
      
        default:
          break;
          
      }

    };

    const createSignPayment = async () => {

      const dataPayment= {
        app_id: props.appID,
        ...props.order,
        is_redirect: true,
      }
      // console.log("dataPayment",dataPayment);

      const dataSignature = await dispatch(createSignature(dataPayment))
      if(dataSignature.payload && dataSignature.payload.url){
        window.location.href= dataSignature.payload.url
      }

    }

    if(msg){
      return <AlertMessage msg={msg} />
    }

    if(!merchant || !methods){
      return <LoadingPage props={props} />
    }

    return (
      <Container>

        <BoxPayment>

          <Title>Chọn phương thức thanh toán</Title>

          <OrderInfo 
            data={{
              order_id: props.order?.order_id,
              total_amount: props.order?.amount,
              ...merchant
            }} 
          />

          {methods.map((item) => (
            <BoxMethod
              key={item._id}
          
              onClick={() => setActiveMethod(item._id)}
            >
              <PaymentMethod item={item} activeMethod={activeMethod} />
            </BoxMethod> 
          ))}

          <BtnCheckout activeMethod={activeMethod} checkout={checkoutOrder} />
          
        </BoxPayment>
        
      </Container>
    );


}
export default PaymentWidget;

