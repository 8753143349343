import React from 'react';
import GlobalStyle from './GlobalStyle';

import PaymentWidget from './pages/widget'
import { WrapperASW } from './pages/checkout/styled';

const App = (props) =>{
  return (
    <WrapperASW>
      <GlobalStyle />
      <PaymentWidget props={props}/>
    </WrapperASW>
  )
}

export default App;